import ranksMapping from '@/json/rank.json';
import aiCharacterMapping from '@/json/aiCharacter.json';
import i18n from '@/i18n/index.js';
import {clientVersion} from '@/constant/env';
/**
 * Enum for kifu type.
 * @readonly
 * @enum {String}
 */
const KifuType = {
  practice: clientVersion === 'hjj-jungo' ? i18n.t('AI對弈') : i18n.t('下棋'),
  pvp: i18n.t('好友對戰'),
  verification: i18n.t('檢定'),
  hjjCourse: i18n.t('課程'),
};

/**
 * 取得黑棋的顯示名稱
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的顯示名稱
 */
function blackName(kifu) {
  if (kifu.gameMode === 'pvp') {
    return kifu.blackNickName;
  }
  if (!kifu.userColor && kifu.otherInfo) {
    return kifu.otherInfo.black;
  }
  if (kifu.userColor === 'black') {
    return kifu.nickName;
  }
  return kifu.opponentName;
}

/**
 * 取得白棋的顯示名稱
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的顯示名稱
 */
function whiteName(kifu) {
  if (kifu.gameMode === 'pvp') {
    return kifu.whiteNickName;
  }
  if (!kifu.userColor && kifu.otherInfo) {
    return kifu.otherInfo.white;
  }
  if (kifu.userColor === 'white') {
    return kifu.nickName;
  }
  return kifu.opponentName;
}

/**
 * 取得黑棋的頭像
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的頭像位置
 */
function blackAvatar(kifu) {
  if (kifu.gameMode === 'pvp') {
    return require(`@/assets/img/personal/avatar/${kifu.blackAvatar}.png?srcset`);
  }
  if (kifu.userColor === 'black') {
    return require(`@/assets/img/personal/avatar/${kifu.userAvatar}.png?srcset`);
  }
  if (kifu.gameMode === 'practice' && kifu.userColor !== 'black') {
    const url = aiCharacterMapping[kifu.opponentName]
      ? `avatar_tsumego_ai_${aiCharacterMapping[kifu.opponentName]}`
      : 'avatar_tsumego_ai_1';
    return require(`@/assets/img/personal/avatar/${url}.png?srcset`);
  }
  return require('@/assets/img/game/ai-black.png?srcset');
}

/**
 * 取得白棋的頭像
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的頭像位置
 */
function whiteAvatar(kifu) {
  if (kifu.gameMode === 'pvp') {
    return require(`@/assets/img/personal/avatar/${kifu.whiteAvatar}.png?srcset`);
  }
  if (kifu.userColor === 'white') {
    return require(`@/assets/img/personal/avatar/${kifu.userAvatar}.png?srcset`);
  }
  if (kifu.gameMode === 'practice' && kifu.userColor !== 'white') {
    const url = aiCharacterMapping[kifu.opponentName]
      ? `avatar_tsumego_ai_${aiCharacterMapping[kifu.opponentName]}`
      : 'avatar_tsumego_ai_1';
    return require(`@/assets/img/personal/avatar/${url}.png?srcset`);
  }
  return require('@/assets/img/game/ai-white.png?srcset');
}

/**
 * 取得黑棋的棋力
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的棋力文字
 */
function blackRank(kifu) {
  if (kifu.gameMode === 'pvp') {
    return ranksMapping[kifu.blackRank]?.label;
  }
  if (kifu.userColor === 'black') {
    return ranksMapping[kifu.userRank]?.label;
  } else if (
    kifu.gameMode === 'verification' ||
    kifu.gameMode === 'hjjCourse'
  ) {
    return '-';
  } else if (kifu.gameMode === 'practice') {
    return kifu.aiId ? 'Lv.' + kifu.aiId : '-';
  }
}

/**
 * 取得白棋的棋力
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的棋力文字
 */
function whiteRank(kifu) {
  if (kifu.gameMode === 'pvp') {
    return ranksMapping[kifu.whiteRank]?.label;
  }
  if (kifu.userColor === 'white') {
    return ranksMapping[kifu.userRank]?.label;
  } else if (
    kifu.gameMode === 'verification' ||
    kifu.gameMode === 'hjjCourse'
  ) {
    return '-';
  } else if (kifu.gameMode === 'practice') {
    return kifu.aiId ? 'Lv.' + kifu.aiId : '-';
  }
}

/**
 * 取得檢定的棋力
 * @param {Object} kifu 後端傳來的棋譜物件
 * @returns {String} 回傳的棋力文字
 */
function verificationRank(kifu) {
  if (kifu.gameMode === 'verification') {
    return ranksMapping[kifu.verificationRank]?.label;
  } else {
    return '';
  }
}

export default {
  KifuType,
  blackName,
  whiteName,
  blackAvatar,
  whiteAvatar,
  blackRank,
  whiteRank,
  verificationRank,
};
