<template>
  <div
    class="kifus position-relative overflow-auto"
    :class="{'jungo-kifus': $isJungo}"
  >
    <base-header
      :title="$t('棋譜')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="false"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      @on-click-left="back2Personal"
    ></base-header>
    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <EmptyPlaceholder
      v-else-if="kifus.length === 0"
      icon="Kifu"
      :text="$t('目前沒有棋譜')"
      :sub-text="$t('下棋後個人棋譜將會顯示在這')"
    />

    <div v-else class="content scroll-bar">
      <div class="kifus-container">
        <div class="kifus-wrapper">
          <div
            v-if="!$isJungo"
            class="top-sign w-100 text-white text-center text-12 d-flex align-items-center justify-content-center"
          >
            <div class="blue-dot ml-1 mr-1"></div>
            <span class="d-flex align-items-center justify-content-center">
              {{ $t('代表有覆盤') }}
            </span>
          </div>
          <div v-if="$device.isMobile" class="cards-wrapper p-3">
            <template v-for="(kifu, index) in kifus">
              <div
                v-if="
                  index > 0 &&
                  isSameDate(
                    kifus[index - 1].finishedTime,
                    kifus[index].finishedTime
                  )
                "
                :key="`date-splitter-${index}`"
                class="date-splitter text-14 text-white text-center mx-auto mt-4"
              >
                {{ kifu.finishedTime | dateFormat('YYYY - MM') }}
              </div>
              <kifu-card
                :key="kifu.finishedTime"
                :kifu="kifu"
                :is-user-won="isUserWon(kifu)"
                :detail="detail(kifu)"
                :game-mode-label="gameModeLabel(kifu)"
                class="kifu-card"
                @kifu-preview="kifuPreview"
              ></kifu-card>
            </template>

            <div v-if="hasInfiniteData" class="infinite-loading mt-5">
              <infinite-loading
                spinner="spiral"
                force-use-infinite-wrapper=".content.scroll-bar"
                @infinite="getMoreKifus"
              ></infinite-loading>
            </div>
          </div>
          <div v-else>
            <b-table
              :items="tableKifus"
              :fields="fields"
              thead-tr-class="table-header"
              responsive="sm"
              class="mb-0"
              hover
            >
              <template #cell(time)="row">
                <div class="wrap-time-stamp d-flex flex-wrap">
                  <div
                    class="badge text-white mr-2"
                    :class="{'bg-secondary': row.item.isUserWon}"
                  >
                    <i v-if="row.item.isUserWon" class="icon-Crown"></i>
                    <i v-else class="icon-Flag"></i>
                  </div>
                  <p class="m-0">{{ row.item.time }}</p>
                </div>
              </template>
              <template #cell(actions)="row" style="position: relative">
                <b-button
                  size="sm"
                  class="mr-1 p-md-0"
                  variant="link"
                  @click="kifuPreview(row.item)"
                >
                  {{ $t('查看') }}
                </b-button>
                <div v-if="row.item.isReviewed" class="blue-dot"></div>
              </template>
            </b-table>

            <div v-if="hasInfiniteData" class="infinite-loading bg-white pt-5">
              <infinite-loading
                spinner="spiral"
                force-use-infinite-wrapper=".content"
                @infinite="getMoreKifus"
              ></infinite-loading>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aiGameService from '@/services/aiGame';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import KifuCard from '@/components/Kifu/KifuCard.vue';
import InfiniteLoading from 'vue-infinite-loading';
import BackEvent from '@/lib/base/backEvent.js';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import kifuInfo from '@/lib/kifu/kifuInfo';
import filters from '@/lib/base/filters.js';
dayjs.extend(relativeTime);
dayjs.locale('zh-tw');

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    KifuCard,
    InfiniteLoading,
    EmptyPlaceholder,
  },
  data() {
    return {
      isPageLoading: true,
      isDataLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('我的棋譜'),
          enable: true,
          router: 'kifus',
        },
      ],
      fields: [
        {key: 'time', label: '時間', sortable: false, tdClass: 'align-middle'},
        {
          key: 'gameModeLabel',
          label: '來源',
          sortable: false,
          tdClass: 'align-middle',
        },
        {
          key: 'type',
          label: '類型/手數',
          sortable: false,
          tdClass: 'align-middle',
        },
        {key: 'black', label: '黑方', sortable: false, tdClass: 'align-middle'},
        {key: 'white', label: '白方', sortable: false, tdClass: 'align-middle'},
        {
          key: 'result',
          label: '結果',
          sortable: false,
          tdClass: 'align-middle',
        },
        {
          key: 'actions',
          label: '操作',
          sortable: false,
          tdClass: 'align-middle',
        },
      ],
      tableKifus: [],
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    kifus() {
      return this.$store.state.aiGame.kifus;
    },
    pvpLastKey() {
      return this.$store.state.aiGame.pvpLastKey;
    },
    pveLastKey() {
      return this.$store.state.aiGame.pveLastKey;
    },
    kifusType() {
      return this.$store.state.aiGame.kifusType;
    },
    kifusAmount() {
      return this.$store.state.aiGame.kifusAmount;
    },
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    hasInfiniteData() {
      return this.pvpLastKey !== 'finish' || this.pveLastKey !== 'finish';
    },
  },
  created() {
    if (!this.$device.isMobile) {
      this.$store.commit('aiGame/setKifuAmount', 24);
    }
    this.getKifus();
  },
  methods: {
    back2Personal() {
      this.$router.push({name: 'personal'});
      BackEvent.clearEvents();
    },
    async getKifus() {
      this.isPageLoading = true;
      try {
        await this.$store.dispatch('aiGame/getKifus', this.userData.username);
        if (!this.$device.isMobile) {
          this.kifusToTable(this.kifus);
        }
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    async getMoreKifus($state) {
      if (!this.isDataLoading) {
        this.isDataLoading = true;
        try {
          const result = await aiGameService.getKifus(
            this.kifusAmount,
            this.userData.username,
            this.kifusType,
            this.pvpLastKey,
            this.pveLastKey
          );

          const kifus = result.data.kifuList;
          if (!this.$device.isMobile) {
            this.kifusToTable(kifus);
          }
          let pveLastKey = this.pveLastKey;
          let pvpLastKey = this.pvpLastKey;
          if (kifus.length === 0) {
            $state.complete();
          } else {
            if (result.data.pveLastKey) {
              pveLastKey = result.data.pveLastKey;
            }
            if (result.data.pvpLastKey) {
              pvpLastKey = result.data.pvpLastKey;
            }

            this.$store.commit('aiGame/setKifus', {
              kifus,
              pveLastKey,
              pvpLastKey,
              first: false,
            });
            $state.loaded();
          }
        } catch {
          this.$Message.error(this.$t('發生未知錯誤，再試一次'));
        } finally {
          this.isDataLoading = false;
        }
      } else {
        $state.loaded();
      }
    },
    kifusToTable(kifus) {
      kifus.forEach((kifu) => {
        const finishedTime = this.timestamp(kifu.finishedTime);
        const convertKifu = {
          ...kifu,
          time: finishedTime,
          type: this.detail(kifu),
          black: `${kifuInfo.blackName(kifu)} [${kifuInfo.blackRank(kifu)}]`,
          white: `${kifuInfo.whiteName(kifu)} [${kifuInfo.whiteRank(kifu)}]`,
          result: filters.winningWayText(
            kifu.winningWay,
            kifu.komi,
            kifu.boardSize
          ),
          isUserWon: this.isUserWon(kifu),
          gameModeLabel: this.gameModeLabel(kifu),
        };
        this.tableKifus.push(convertKifu);
      });
    },
    async kifuPreview(kifu) {
      if (kifu.isReviewed) {
        const result = await this.reviewGame(kifu);
        this.$store.commit('aiGame/setKifuReviewGame', result);
      }
      this.$store.commit('aiGame/setKifuData', kifu);
      return this.$router.push(
        `/personal/kifu/${kifu.createdTime || kifu.id}/${kifu.gameMode}`
      );
    },
    async reviewGame(kifu) {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        const result = await aiGameService.reviewGame({
          id: kifu.id,
          gameMode: kifu.gameMode,
          coursePlan: this.currentPlan.id,
        });
        return result;
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    gameModeLabel(kifu) {
      return kifuInfo.verificationRank(kifu) + kifuInfo.KifuType[kifu.gameMode];
    },
    detail(kifu) {
      if (kifu.gameMode === 'pvp') {
        return `${kifu.boardSize}${this.$t('路')} - ${kifu.step}${this.$t(
          '手'
        )}`;
      } else {
        return `${kifu.gameTitle} - ${kifu.step}${this.$t('手')}`;
      }
    },
    timestamp(finishedTime) {
      if (dayjs().diff(dayjs(finishedTime), 'h') < 24) {
        return dayjs(finishedTime).fromNow();
      } else {
        const format = 'YYYY-MM-DD';
        return dayjs(finishedTime).format(format);
      }
    },
    isUserWon(kifu) {
      if (kifu.gameMode == 'pvp') {
        return (
          (kifu.winner == 'black' && this.userData.username == kifu.black) ||
          (kifu.winner == 'white' && this.userData.username == kifu.white)
        );
      }
      return kifu.userColor === kifu.winner;
    },
    isSameDate(prevDate, currentDate) {
      return !dayjs(prevDate).isSame(currentDate, 'month');
    },
  },
};
</script>

<style lang="scss" scoped>
.kifus {
  .empty {
    height: calc(100% - 56px) !important;
  }
  .content {
    height: calc(100% - 56px);
    overflow: overlay;
    .top-sign {
      line-height: 20px;
      background: rgba(0, 0, 0, 0.5);
      .blue-dot {
        width: 16px;
        height: 16px;
        background-color: $infoAndLink;
        border-radius: 50%;
      }
    }
    .date-splitter {
      width: 110px;
      line-height: 28px;
      background: $grayscale-20;
      border-radius: 14px;
    }
    .kifu-card:not(:first-child) {
      margin-top: 8px;
    }
    .kifu-card:not(:last-child) {
      margin-bottom: 16px;
    }
    .infinite-loading /deep/ .infinite-status-prompt {
      padding: 0 !important;
      .loading-spiral {
        padding: 0;
        width: 16px;
        height: 16px;
        border: 2px solid $secondary;
        border-right-color: transparent;
      }
    }
  }
  &.jungo-kifus {
    .content .top-sign .blue-dot,
    /deep/.kifu-card .blue-dot {
      background-color: $cyan-blue;
    }
  }
}

@media screen and (min-width: 768px) {
  .kifus {
    .empty {
      height: calc(100% - 80px) !important;
    }
    .kifus-container {
      max-width: 100%;
      margin: 40px auto;
      padding: 0 32px;
    }
    .kifus-wrapper {
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
      border-radius: 10px;
      overflow: hidden;
    }
    .content {
      height: calc(100vh - 80px);
      overflow: overlay;
      .top-sign {
        background: rgba(0, 0, 0, 0.2);
        color: $font-grayscale-1;
      }
      /deep/ .table {
        .table-header {
          th {
            background: rgba(0, 0, 0, 0.05);
            border-bottom: none;
            color: $font-grayscale-1;
            padding: 16px 10px;
            line-height: 28px;
            width: 14.28571%;
          }
        }
        tbody {
          tr {
            background: white;
            td {
              position: relative;
              height: 60px;
              width: 14.28571%;
              padding: 16px 10px;
              &:not(:first-of-type) {
                border-top: 1px solid #dcdfe5 !important;
              }
              .wrap-time-stamp {
                flex-wrap: wrap;
                align-items: center;
                p {
                  flex: 1 0 0;
                }
              }
              .badge {
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 12px;
                background: $grayscale-30;
                font-size: 20px;
                padding: 2px 0px 2px;
              }
              .blue-dot {
                position: absolute;
                right: 8px;
                top: 8px;
                width: 14px;
                height: 14px;
                background-color: $infoAndLink;
                border-radius: 50%;
              }
            }
          }
        }
      }
      /deep/ .table-hover tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .kifus {
    .kifus-container {
      max-width: 1172px;
    }
  }
}
</style>
