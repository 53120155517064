var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kifus position-relative overflow-auto",class:{'jungo-kifus': _vm.$isJungo}},[_c('base-header',{attrs:{"title":_vm.$t('棋譜'),"is-title-bold":true,"left-arrow":true,"back-on-click-left":false,"breadcrumb":_vm.breadcrumb,"mode":_vm.$device.isMobile ? 'secondary' : 'primary'},on:{"on-click-left":_vm.back2Personal}}),(_vm.isPageLoading)?_c('loading-wrapper'):(_vm.kifus.length === 0)?_c('EmptyPlaceholder',{attrs:{"icon":"Kifu","text":_vm.$t('目前沒有棋譜'),"sub-text":_vm.$t('下棋後個人棋譜將會顯示在這')}}):_c('div',{staticClass:"content scroll-bar"},[_c('div',{staticClass:"kifus-container"},[_c('div',{staticClass:"kifus-wrapper"},[(!_vm.$isJungo)?_c('div',{staticClass:"top-sign w-100 text-white text-center text-12 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"blue-dot ml-1 mr-1"}),_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t('代表有覆盤'))+" ")])]):_vm._e(),(_vm.$device.isMobile)?_c('div',{staticClass:"cards-wrapper p-3"},[_vm._l((_vm.kifus),function(kifu,index){return [(
                  index > 0 &&
                  _vm.isSameDate(
                    _vm.kifus[index - 1].finishedTime,
                    _vm.kifus[index].finishedTime
                  )
                )?_c('div',{key:`date-splitter-${index}`,staticClass:"date-splitter text-14 text-white text-center mx-auto mt-4"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(kifu.finishedTime,'YYYY - MM'))+" ")]):_vm._e(),_c('kifu-card',{key:kifu.finishedTime,staticClass:"kifu-card",attrs:{"kifu":kifu,"is-user-won":_vm.isUserWon(kifu),"detail":_vm.detail(kifu),"game-mode-label":_vm.gameModeLabel(kifu)},on:{"kifu-preview":_vm.kifuPreview}})]}),(_vm.hasInfiniteData)?_c('div',{staticClass:"infinite-loading mt-5"},[_c('infinite-loading',{attrs:{"spinner":"spiral","force-use-infinite-wrapper":".content.scroll-bar"},on:{"infinite":_vm.getMoreKifus}})],1):_vm._e()],2):_c('div',[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.tableKifus,"fields":_vm.fields,"thead-tr-class":"table-header","responsive":"sm","hover":""},scopedSlots:_vm._u([{key:"cell(time)",fn:function(row){return [_c('div',{staticClass:"wrap-time-stamp d-flex flex-wrap"},[_c('div',{staticClass:"badge text-white mr-2",class:{'bg-secondary': row.item.isUserWon}},[(row.item.isUserWon)?_c('i',{staticClass:"icon-Crown"}):_c('i',{staticClass:"icon-Flag"})]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(row.item.time))])])]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-1 p-md-0",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.kifuPreview(row.item)}}},[_vm._v(" "+_vm._s(_vm.$t('查看'))+" ")]),(row.item.isReviewed)?_c('div',{staticClass:"blue-dot"}):_vm._e()]}}])}),(_vm.hasInfiniteData)?_c('div',{staticClass:"infinite-loading bg-white pt-5"},[_c('infinite-loading',{attrs:{"spinner":"spiral","force-use-infinite-wrapper":".content"},on:{"infinite":_vm.getMoreKifus}})],1):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }