<template>
  <div class="kifu-card">
    <div
      class="card-head text-12 d-flex align-item-center justify-content-between"
    >
      <span class="game-mode-label">{{ gameModeLabel }}</span>
      <span class="date pl-3">{{
        kifu.finishedTime | dateFormat('YYYY-MM-DD')
      }}</span>
    </div>
    <div
      class="card px-2 flex-row align-items-center justify-content-between"
      @click="onClickCard"
    >
      <div v-if="kifu.isReviewed" class="blue-dot position-absolute"></div>
      <div class="black">
        <div class="avatar-container d-flex flex-column align-items-center">
          <div class="position-relative">
            <img class="rounded-circle" :srcset="blackPlayer.avatar" />
            <div class="avatar-chess black-chess"></div>
          </div>
          <div class="avatar-text text-12">
            <span class="name text-truncate">{{ blackPlayer.name }} </span
            ><span>[{{ blackPlayer.rank }}]</span>
          </div>
        </div>
      </div>
      <div
        class="detail d-flex flex-column justify-content-center position-absolute text-center text-12"
      >
        <div
          class="result text-white mx-auto"
          :class="isUserWon ? 'bg-secondary' : ''"
        >
          <i v-if="isUserWon" class="icon-Crown"></i>
          {{ winningWayText }}
        </div>
        <span> {{ detail }} </span>
      </div>
      <div class="white">
        <div class="avatar-container d-flex flex-column align-items-center">
          <div class="position-relative">
            <img class="rounded-circle" :srcset="whitePlayer.avatar" />
            <div class="avatar-chess white-chess"></div>
          </div>
          <div class="avatar-text text-12">
            <span class="name text-truncate">{{ whitePlayer.name }} </span
            ><span>[{{ whitePlayer.rank }}]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ranksMapping from '@/json/rank.json';
import kifuInfo from '@/lib/kifu/kifuInfo';
import filters from '@/lib/base/filters.js';

export default {
  components: {},
  props: {
    kifu: {
      type: Object,
      default() {
        return {};
      },
    },
    isUserWon: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: String,
      default: '',
    },
    gameModeLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ranksMapping,
    };
  },
  computed: {
    blackPlayer() {
      return {
        avatar: kifuInfo.blackAvatar(this.kifu),
        name: kifuInfo.blackName(this.kifu),
        rank: kifuInfo.blackRank(this.kifu),
      };
    },
    whitePlayer() {
      return {
        avatar: kifuInfo.whiteAvatar(this.kifu),
        name: kifuInfo.whiteName(this.kifu),
        rank: kifuInfo.whiteRank(this.kifu),
      };
    },
    winningWayText() {
      return filters.winningWayText(
        this.kifu.winningWay,
        this.kifu.komi,
        this.kifu.boardSize
      );
    },
  },
  methods: {
    async onClickCard() {
      this.$emit('kifu-preview', this.kifu);
    },
  },
};
</script>

<style lang="scss" scoped>
.kifu-card {
  .game-mode-label {
    color: $font-grayscale-2;
  }
  .date {
    min-width: 85px;
    color: $font-grayscale-3;
  }
  .card {
    margin-top: 2px;
    height: 78px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
  }
  .blue-dot {
    width: 16px;
    height: 16px;
    background-color: $infoAndLink;
    border-radius: 50%;
    top: 8px;
    right: 8px;
  }
  .black,
  .white {
    width: 93px;
  }
  .detail {
    top: 0;
    bottom: 0;
    left: calc(50% - 60px);
    .result {
      width: 120px;
      height: 20px;
      margin-bottom: 3px;
      line-height: 20px;
      border-radius: 10px;
      background: $grayscale-30;
    }
  }
  .avatar-container {
    color: $font-grayscale-1;
    img {
      width: 40px;
      height: 40px;
    }
    .avatar-chess {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      &.black-chess {
        border: solid 1px #ffffff;
        background-color: #4a4141;
      }
      &.white-chess {
        border: solid 1px $line-2;
        background-color: #ffffff;
      }
    }
    .avatar-text {
      margin-top: 2px;
    }
    .name {
      max-width: 60px;
    }
  }
}
</style>
